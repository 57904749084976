import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';

const Wrapper = styled.div`
`

export default function OpcSubUsagesTable() {

    const { appState, setAppState } = useContext(AppContext);
    const [data, setData] = useState([])
    const gridRef = useRef();


    // const apiUrl = process.env.REACT_APP_OPC_API_ENDPOINT;
    // const apiUrl = 'http://localhost:3030';
    const apiUrl = 'https://opc-api-xeu74yuiwq-ez.a.run.app'

    useEffect(() => {
        try {


            const getData = async () => {
                const options = {

                }
                const response = await axios.post(`${apiUrl}/api/subUsagesPerChargerId`, null, {
                    headers: {
                        authorization: appState.user.accessToken,
                        refresh_token: appState.user.refreshToken
                    }
                })
                if(response.headers.access_token && response.headers.refresh_token) {
                    setAppState({...appState, user: {...appState.user, accessToken: response.headers.access_token, refreshToken: response.headers.refresh_token}})
                }
                setAppState({
                    ...appState,
                    grid: gridRef
                })
                setData(response.data)
            }

            getData()
            

        } catch (e) {
            console.error(e)
        }
    }, [])


    
    const dateFilterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
                return 0;
            }

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date

            const cellDate = new Date(dateAsString);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        }
    };

    const getDate = params => {
        const dateString = params.value
        const year = dateString.substr(0, 4)
        const month = dateString.substr(5, 2)
        const day = dateString.substr(8, 2)
        const hour = dateString.substr(11, 2)
        const min = dateString.substr(14, 2)
        const sec = dateString.substr(17, 2)

        // console.log(`${year}-${month}-${day} ${hour}:${min}:${sec}`)
        return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    }

    const defaultColDef = {
        filter: true,
        resizable: true,
        sortable: true
    }


    return (
        <Wrapper>
            <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 178px)' }}>
                <AgGridReact 
                    rowData={data} 
                    defaultColDef={defaultColDef} 
                    ensureDomOrder={true}
                    enableCellTextSelection={true}
                    enableRangeSelection={true}
                    ref={gridRef}

                    >
                    <AgGridColumn headerName="ID Utilização" field="idUsage"></AgGridColumn>
                    <AgGridColumn headerName="ID Posto" field="idChargingStation"></AgGridColumn>
                    <AgGridColumn headerName="ID Sub Consumo" field="idSubUsage"></AgGridColumn>
                    <AgGridColumn headerName="Data" field="idDay" filter="agDateColumnFilter" filterParams={dateFilterParams} valueFormatter={getDate}></AgGridColumn>
                    <AgGridColumn headerName="Energia Total Período" field="energia_total_periodo"></AgGridColumn>
                    <AgGridColumn headerName="Duração (Min)" field="periodDuration"></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC" field="preco_opc" ></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC Ativação" field="preco_opc_ativacao"></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC Energia" field="preco_opc_energia"></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC Tempo" field="preco_opc_tempo"></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC Unitário Ativação" field="preco_unitario_opc_ativacao"></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC Unitário Energia" field="preco_unitario_opc_energia"></AgGridColumn>
                    <AgGridColumn headerName="Preço OPC Unitário Tempo" field="preco_unitario_opc_tempo"></AgGridColumn>
                </AgGridReact>
            </div>
        </Wrapper>
    )
}












