import React, { useContext, useEffect } from 'react'

import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar';
import Opc from '../public/opc/Opc';
import Wallboxes from '../private/homecharging/Wallboxes';
import { AppContext } from '../../contexts/AppContext';

export default function Router() {

    const {appState} = useContext(AppContext)

    // useEffect(() => {
    //   window.location.href="/publico/opc"        
    // }, [])


    return (
        <BrowserRouter>
            <Header />
            <Sidebar />
            <Switch>
                {/* <Route path="/privado/wallboxes" component={Wallboxes}/> */}
                <Route path="/publico/opc" component={Opc}/>
                <Route exact path="/"  render={() => {
                    console.log("render")
                    return (appState.user ? 
                        <Redirect to="/publico/opc/usages" /> : 
                        null
                    )
                }} />
            </Switch>
        </BrowserRouter>
    )
}
