import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { Route, useHistory } from 'react-router'
import { ContentWrapper } from '../../../components/common/ContentWrapper'
import { SubContent } from '../../../components/common/SubContent'
import { SubHeader } from '../../../components/common/SubHeader'
import TabsStripe from '../../../components/common/TabsStripe'
import { PageContext } from '../../../contexts/PageContext'
import { SideTab } from '../../private/homecharging/subPages/SideTab'
import OpcSubUsagesTable from './sub/OpcSubUsagesTable'
import OpcUsagesTable from './sub/OpcUsagesTable'

export default function Opc({ match }) {
    const {page, setPage} = useContext(PageContext)
    const [tab, setTab] = useState(0)
    const baseUrl = "/publico/opc"
    const tabs = [{name: "Consumos", path: `${baseUrl}/usages`}, {name: "SubConsumos", path: `${baseUrl}/subusages`}]

    useEffect(() => {
        setPage("opc")
    }, [page])

    return (
        <ContentWrapper>
            <SubHeader title="OPC">
                <TabsStripe tabState={[tab, setTab]} tabs={tabs}/>
                {/* <SideTab name={"Consumos"} onClick={() => history.push(`${match.url}/usages`)}></SideTab> */}
            </SubHeader>
            <SubContent>
                <Route path={match.url + "/usages"} component={OpcUsagesTable} />
                <Route path={match.url + "/subusages"} component={OpcSubUsagesTable} />
                {/* <Route path={match.url + "/"} component={OpcUsagesTable} /> */}
            </SubContent>
        </ContentWrapper>
    )
}