import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default function Loading() {
    const styles = () => {
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "20rem"
        }
    }
    return (
        <div style={styles()}>
            <CircularProgress size={50} className="loadingCircle" />
        </div>
    )
}
