import { Button } from "@material-ui/core";
import styled from "styled-components/macro";
import DownloadIcon from '@material-ui/icons/GetApp';
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";


const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: auto;
    align-items: center;
    wrap

`;

const LeftAligned = styled.div`
    display: flex;
`

const Title = styled.span`
    font-size: 20pt;
    font-weight: 600;
    margin-right: 30px;
`

const Action = styled.span`
    
`

export const SubHeader = ({title, children}) => {

    const {appState} = useContext(AppContext)

    const downloadCsv = () => {
        console.log("download csv")
        if(appState && appState.grid) {
            appState.grid.current.api.exportDataAsCsv();
        }
      }

    return <Container>
            <LeftAligned>
                <Title>{title}</Title>
                {children}
            </LeftAligned>
            <Action>
                <Button onClick={downloadCsv} startIcon={<DownloadIcon />}>CSV</Button>
            </Action>
        </Container>
}